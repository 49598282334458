<template>
  <v-container
    tag="section"
    fluid
  >
    <h2>Wettkampftage</h2>
    <v-card>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="creatematchday()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>
      <v-data-table
        :headers="matchdayheaders"
        :items="matchdays"
        :mobile-breakpoint="0"
        :items-per-page="15"
        sort-by="startDate"
      >
        <template #item.name="{item}">
          <base-edit-dialog
            :value="item.name || ' '"
            :id="item._id"
            label="Name"
            @input="({ value, id }) => savematchdayfield(id, 'name', value)"
          />
        </template>
        <template #item.startDate="{item}">
          <base-edit-dialog-date
            :value="item.startDate"
            :id="item._id"
            label="von"
            @input="({ value, id }) => savematchdayfield(id, 'startDate', value, 'Date')"
          />
        </template>
        <template #item.endDate="{item}">
          <base-edit-dialog-date
            :value="item.endDate"
            :id="item._id"
            label="bis"
            @input="({ value, id }) => savematchdayfield(id, 'endDate', value, 'Date')"
          />
        </template>
        <template #item.pairs="{item}">
          <v-btn
            text
            small
            fab
            @click="openpairs(item._id)"
          >
            {{ (item.pairs || []).length }}
          </v-btn>
        </template>
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click="deletematchday(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <base-material-dialog
      :value="pairsdialog.open"
      icon="far fa-clipboard-list"
      title="Paarungen bearbeiten"
      color="primary"
      :actions="[ 'close' ]"
      @close="pairsclose"
      @esc="pairsclose"
    >
      <v-card
        flat
        class="mt-8"
      >
        <v-btn
          absolute
          top
          right
          fab
          small
          @click="createpair()"
        >
          <v-icon>
            fa-plus
          </v-icon>
        </v-btn>
        <v-data-table
          :headers="[{text:'Heim',value:'home',sortable:false},{text:'Gast',value:'guest',sortable:false},{text:'Löschen',value:'delete',sortable:false,align:'center'}]"
          :items="pairsdialog.pairs"
        >
          <template #item.delete="{item}">
            <v-btn
              text
              small
              fab
              @click="deletepair(item)"
            >
              <v-icon>
                far fa-trash-alt
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </base-material-dialog>
    <base-material-dialog
      :value="pairsdialog.newpair.open"
      icon="far fa-clipboard-list"
      title="Paarung hinzufügen"
      color="primary"
      :actions="[ 'save', 'close' ]"
      :width="400"
      @close="newpairclose"
      @esc="newpairclose"
      @save="newpairsave"
      @enter="newpairsave"
    >
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="Heim-Ziffer"
            v-model="pairsdialog.newpair.home"
            type="number"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Gast-Ziffer"
            v-model="pairsdialog.newpair.guest"
            type="number"
          />
        </v-col>
      </v-row>
    </base-material-dialog>

    <h2>Ligen</h2>
    <v-card>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="createleague()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-btn
        absolute
        top
        right
        fab
        small
        style="margin-right:66px;"
        @click="syncleagues()"
      >
        <span class="fa-stack fa-1x">
          <i :class="{'fa': true,'fa-sync': true,'fa-stack-2x':true,'fa-spin':sync.leagues.in_progress}" />
          <i
            class="fa fa-bars fa-stack-1x"
            style="margin-left:6px;margin-top:6px;color:black;font-size:140%;text-shadow:0 0 2px white;"
          />
        </span>
      </v-btn>

      <v-btn
        absolute
        top
        right
        fab
        small
        style="margin-right:115px;"
        @click="fetchteams()"
      >
        <span class="fa-stack fa-1x">
          <i :class="{'fa': true,'fa-sync': true,'fa-stack-2x':true,'fa-spin':sync.teams.in_progress}" />
          <i
            class="fa fa-user-friends fa-stack-1x"
            style="margin-left:6px;margin-top:6px;color:black;font-size:140%;text-shadow:0 0 2px white;"
          />
        </span>
      </v-btn>

      <v-data-table
        :headers="headers"
        :items="EventFind"
        :mobile-breakpoint="0"
        :items-per-page="15"
        sort-by="order"
      >
        <template #item.name="{item}">
          <base-edit-dialog
            :value="item.name || ' '"
            :id="item._id"
            label="Name"
            @input="({ value, id }) => savefield(id, 'name', value)"
          />
        </template>
        <template #item.shortName="{item}">
          <base-edit-dialog
            :value="item.shortName || ' '"
            :id="item._id"
            label="Kürzel"
            @input="({ value, id }) => savefield(id, 'shortName', value)"
          />
        </template>
        <template #item.order="{item}">
          <base-edit-dialog
            :value="item.order || ' '"
            :id="item._id"
            label="Order"
            @input="({ value, id }) => savefield(id, 'order', value, 'Int')"
          />
        </template>
        <template #item.teams="{item}">
          <v-btn
            text
            small
            fab
            @click="openteams(item._id, item.teams)"
          >
            {{ (item.teams || []).length }}
          </v-btn>
        </template>
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click="del(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
        <template #item.identifiers="{item}">
          <v-btn
            text
            small
            fab
            @click="openidentifiers(item)"
          >
            {{ (item.identifiers || []).length }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <identifiers
      v-model="identifiersdialog.open"
      :id="identifiersdialog.id"
      :identifiers="identifiersdialog.identifiers"
      collection="StbM2021Liga"
    />

    <base-material-dialog
      :value="sync.teams.open"
      icon="far fa-user-friends"
      title="Mannschaften hinzufügen"
      color="primary"
      :actions="[ 'save', 'close' ]"
      @close="teamsclose"
      @esc="teamsclose"
      @save="teamssave"
    >
      <v-data-table
        :headers="sync.teams.headers"
        :items="sync.teams.data"
        :mobile-breakpoint="0"
        :items-per-page="-1"
      >
        <template #item.league="{item}">
          <template v-if="item.liga">
            {{ item.liga.name }}
          </template>
          <template v-else>
            Mehrere Ligen!
          </template>
        </template>

        <template #item.clubteam="{item}">
          <template v-if="item.clubteam">
            {{ item.clubteam.name }}
          </template>
          <template v-else>
            <v-select
              v-model="item._team"
              :items="item.clubteams"
              item-text="name"
              item-value="_id"
            />
          </template>
        </template>

        <template #item.order="{item}">
          <template v-if="item.ligateam">
            {{ item.order }}
          </template>
          <template v-else>
            <v-text-field
              v-model="item.order"
              type="number"
            />
          </template>
        </template>

        <template #item.state="{item}">
          <v-icon
            v-if="item.ligateam"
          >
            far fa-check
          </v-icon>
          <v-icon
            v-else-if="item._liga && item._team && !isNaN(parseInt(item.order))"
          >
            far fa-save
          </v-icon>
        </template>
      </v-data-table>
    </base-material-dialog>

    <base-material-dialog
      :value="teams.open"
      icon="far fa-user-friends"
      title="Mannschaften bearbeiten"
      color="primary"
      :actions="[ 'close' ]"
      @close="closeteams"
      @esc="closeteams"
    >
      <v-card flat class="pt-8">
        <v-btn
          absolute
          top
          right
          fab
          small
          class="mt-8"
          @click="() => { addteam.id = null; addteam.open = true }"
        >
          <v-icon>
            fa-plus
          </v-icon>
        </v-btn>

        <v-data-table
          :items="teams.teams"
          sort-by="order"
          :items-per-page="-1"
          :headers="[
            { text: 'Reihenfolge', value: 'order', sortable: false },
            { text: 'Mannschaft', value: 'team.name', sortable: false },
            { text: 'Löschen', value: 'delete', sortable: false }
          ]"
        >
          <template #item.delete="{item}">
            <v-btn
              text
              small
              fab
              @click="delteam(item._team)"
            >
              <v-icon>
                far fa-trash-alt
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </base-material-dialog>

    <base-material-dialog
      :value="addteam.open"
      icon="far fa-user-friends"
      title="Mannschaft hinzufügen"
      color="primary"
      :actions="[ 'close' ]"
      @close="addteam.open = false"
      @esc="addteam.open = false"
    >
      <base-edit-dialog-mannschaftsuche
        label="Mannschaft suchen"
        gender="m"
        :value="addteam.id"
        @input="teamadd"
      />

    </base-material-dialog>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { deleteQuery, updateQuery, useGraphQL } from '@/plugins/graphql'
import { mapGetters } from 'vuex'

import Identifiers from '../../../core/dialogs/identifiers'

const query = `
  _id
  identifiers { name value }
  type
  ... on StbM2021League {
    name
    shortName
    order
    teams {
      _team
      team { _id name }
      order
    }
  }
`

const evquery = `
  _id
  ... on StbM2021 {
    matchdays {
      _id
      name
      startDate endDate
      pairs { home guest }
    }
  }
`

export default {
  name: 'season',

  components: {
    Identifiers
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    Event: {},
    headers: [
      {
        text: 'Reihenfolge',
        value: 'order',
        sortable: false
      },
      {
        text: 'Kürzel',
        value: 'shortName',
        sortable: false
      },
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'Mannschaften',
        value: 'teams',
        sortable: false,
        align: 'center'
      },
      {
        text: 'IDs',
        value: 'identifiers',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Löschen',
        value: 'delete',
        sortable: false,
        align: 'center'
      }
    ],
    matchdayheaders: [
      {
        text: 'Name',
        value: 'name',
        sortable: false
      },
      {
        text: 'von',
        value: 'startDate',
        sortable: false
      },
      {
        text: 'bis',
        value: 'endDate',
        sortable: false
      },
      {
        text: 'Paarungen',
        value: 'pairs',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Löschen',
        value: 'delete',
        sortable: false,
        align: 'center'
      }
    ],
    identifiersdialog: {
      open: false,
      identifiers: [],
      id: ''
    },
    pairsdialog: {
      open: false,
      matchday: null,
      pairs: [],
      newpair: {
        open: false,
        home: 0,
        guest: 0
      }
    },
    sync: {
      leagues: {
        in_progress: false
      },
      teams: {
        in_progress: false,
        data: [],
        open: false,
        headers: [
          {
            text: 'Team-ID',
            value: 'teamId',
            sortable: false
          },
          {
            text: 'Liga',
            value: 'league',
            sortable: false
          },
          {
            text: 'Mannschaft',
            value: 'clubteam',
            sortable: false
          },
          {
            text: 'Reihenfolge',
            value: 'order',
            sortable: false
          },
          {
            text: 'Status',
            value: 'state',
            sortable: false,
            align: 'center'
          }
        ]
      }
    },
    teams: {
      open: false,
      league: null,
      teams: []
    },
    addteam: {
      open: false,
      id: null
    }
  }),

  computed: {
    ...mapGetters(['loading']),
    matchdays () {
      return this.Event?.matchdays || []
    }
  },

  methods: {
    createleague () {
      this.mutate({
        mutation: gql`
          mutation($parent: UUID!, $name: String!) {
            StbM2021LigaCreate(parent: $parent, name: $name) { ${query} }}
          `,
        variables: {
          parent: this.id,
          name: ''
        }
      })
    },
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) { StbM2021LigaDelete(id: $id) }
          `,
        variables: {
          id
        }
      })
    },
    savefield (id, field, value, type) {
      if (!type) type = 'String'
      if (type === 'Int') value = parseInt(value)

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            StbM2021LigaUpdate${q}(id: $id, ${field}: $${field}) { ${query} }
          }
        `,
        variables: {
          id,
          [field]: value
        }
      })
    },
    openidentifiers (item) {
      this.identifiersdialog.id = item._id || ''
      this.identifiersdialog.identifiers = item.identifiers || []
      this.identifiersdialog.open = true
    },
    syncleagues () {
      this.sync.leagues.in_progress = true

      this.mutate({
        mutation: gql`
          mutation($parent: UUID!) {
            StbM2021LigaGymnetImport(parent: $parent) { ${query} }
          }
        `,
        variables: {
          parent: this.id
        }
      }).then(() => {
        this.sync.leagues.in_progress = false
      })
    },
    fetchteams () {
      this.sync.teams.in_progress = true

      this.query({
        query: gql`
          query($parent: UUID!) {
            StbM2021LigaGymnetMannschaften(parent: $parent) {
              teamId
              leagues {
                _id
                name shortName
                order
                teams { _team order identifiers { name value } }
              }
              clubs {
                _id
                name
                teams { _id }
              }
              clubteams {
                _id
                name
              }
            }
          }
        `,
        variables: {
          parent: this.id
        }
      }).then((data) => {
        this.sync.teams.in_progress = false
        this.sync.teams.open = true
        this.sync.teams.data = (data?.StbM2021LigaGymnetMannschaften || []).map(m => {
          const liga = m.leagues.length === 1 ? m.leagues[0] : m.leagues.find(l =>
            l.teams?.find(t =>
              t._team && t.identifiers?.find(i =>
                i.name === 'gymnet.teamId' && parseInt(i.value) === m.teamId
              )
            )
          )

          const clubteam = m.clubteams.length === 1 ? m.clubteams[0] : m.clubteams?.find(c => c._id === liga?.teams?.find(t =>
            t._team && t.identifiers?.find(i =>
              i.name === 'gymnet.teamId' && parseInt(i.value) === m.teamId
            )
          )?._team)

          const ligateam = liga?.teams?.find(t =>
            t._team && t.identifiers?.find(i =>
              i.name === 'gymnet.teamId' && parseInt(i.value) === m.teamId
            )
          )

          return {
            ...m,
            liga,
            clubteam,
            ligateam,
            _liga: liga?._id,
            _team: clubteam?._id,
            order: ligateam?.order
          }
        }).sort((a, b) => {
          if (a.order !== undefined && b.order !== undefined) {
            if (a.liga && b.liga && a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
            return parseInt(a.order) < parseInt(b.order) ? -1 : 1
          }
          if (a.order === undefined && b.order === undefined) {
            if (a.liga && b.liga && a.liga.order !== b.liga.order) return a.liga.order < b.liga.order ? -1 : 1
            if (a.clubteam && b.clubteam) return a.clubteam.name < b.clubteam.name ? -1 : 1
            return a.teamId < b.teamId ? -1 : 1
          }
          if (a.order !== undefined) return 1
          if (b.order !== undefined) return -1
        })
      })
    },
    teamsclose () {
      this.sync.teams.data = []
      this.sync.teams.open = false
    },
    teamssave () {
      const send = this.sync.teams.data
        .filter(t => !t.ligateam && t._liga && t._team && !isNaN(parseInt(t.order)))
        .map(t => ({ id: t._liga, team: t._team, order: parseInt(t.order), teamId: t.teamId }))

      send.forEach(s => {
        this.mutate({
          mutation: gql`
            mutation($id: UUID!, $team: UUID!, $order: Int!, $teamId: Int!) {
              StbM2021LigaUpdateTeamAdd(id: $id, team: $team, order: $order, teamId: $teamId) {
                ${query}
              }
            }
          `,
          variables: s
        })
      })
      this.sync.teams.data = []
      this.sync.teams.open = false
    },
    creatematchday () {
      this.mutate({
        mutation: gql`
            mutation($id: UUID!, $name: String!, $startDate: Date!, $endDate: Date!) {
              StbM2021UpdateMatchdayAdd(id: $id, name: $name, startDate: $startDate, endDate: $endDate) {
                _id
              }
            }
          `,
        variables: {
          id: this.id,
          name: '',
          startDate: new Date().toISOString().substr(0, 10),
          endDate: new Date().toISOString().substr(0, 10)
        }
      })
    },
    deletematchday (matchday) {
      this.mutate({
        mutation: gql`
            mutation($id: UUID!, $matchday: UUID!) {
              StbM2021UpdateMatchdayDelete(id: $id, matchday: $matchday) {
                _id
              }
            }
          `,
        variables: {
          id: this.id,
          matchday
        }
      })
    },
    savematchdayfield (matchday, field, value, type) {
      if (!type) type = 'String'
      if (type === 'Int') value = parseInt(value)

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $matchday: UUID!, $${field}: ${type}!) {
            StbM2021MatchdayUpdate${q}(id: $id, matchday: $matchday, ${field}: $${field}) { _id }
          }
        `,
        variables: {
          id: this.id,
          matchday,
          [field]: value
        }
      })
    },
    openpairs (matchday) {
      this.pairsdialog.matchday = matchday
      this.pairsdialog.pairs = this.Event.matchdays?.find(m => m._id === matchday)?.pairs || []
      this.pairsdialog.open = true
    },
    pairsclose () {
      this.pairsdialog.open = false
      this.pairsdialog.matchday = null
      this.pairsdialog.pairs = []
      this.pairsdialog.newpair.home = 0
      this.pairsdialog.newpair.guest = 0
      this.pairsdialog.newpair.open = false
    },
    deletepair (item) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $matchday: UUID!, $home: Int!, $guest: Int!) {
            StbM2021MatchdayUpdatePairDelete(id: $id, matchday: $matchday, home: $home, guest: $guest) { _id }
          }
        `,
        variables: {
          id: this.id,
          matchday: this.pairsdialog.matchday,
          home: item.home,
          guest: item.guest
        }
      })
    },
    createpair () {
      this.pairsdialog.newpair.home = 0
      this.pairsdialog.newpair.guest = 0
      this.pairsdialog.newpair.open = true
    },
    newpairclose () {
      this.pairsdialog.newpair.home = 0
      this.pairsdialog.newpair.guest = 0
      this.pairsdialog.newpair.open = false
    },
    newpairsave () {
      const h = parseInt(this.pairsdialog.newpair.home)
      const g = parseInt(this.pairsdialog.newpair.guest)
      if (h === 0 || g === 0 || h === g) return

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $matchday: UUID!, $home: Int!, $guest: Int!) {
            StbM2021MatchdayUpdatePairAdd(id: $id, matchday: $matchday, home: $home, guest: $guest) { _id }
          }
        `,
        variables: {
          id: this.id,
          matchday: this.pairsdialog.matchday,
          home: h,
          guest: g
        }
      })
      this.newpairclose()
    },
    openteams (league, teams) {
      console.log(league)
      this.teams.open = true
      this.teams.league = league
      this.teams.teams = teams || []
    },
    closeteams () {
      this.teams.open = false
      this.teams.league = null
      this.teams.teams = []
    },
    delteam (team) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!) {
            StbM2021LigaUpdateTeamDelete(id: $id, team: $team) { _id }
          }
        `,
        variables: {
          id: this.teams.league,
          team
        }
      })
    },
    teamadd (team) {
      if (!team) return

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!, $order: Int!) {
            StbM2021LigaUpdateTeamAdd(id: $id, team: $team, order: $order) { _id }
          }
        `,
        variables: {
          id: this.teams.league,
          team,
          order: 0
        }
      })

      this.addteam.open = false
    }
  },

  watch: {
    EventFind: {
      deep: true,
      handler (val) {
        if (this.identifiersdialog.open) {
          this.identifiersdialog.identifiers = val.find(c => c._id === this.identifiersdialog.id)?.identifiers || []
        }

        if (this.teams.open) {
          this.teams.teams = val.find(l => l._id === this.teams.league)?.teams || []
        }
      }
    },
    Event: {
      deep: true,
      handler (val) {
        if (this.pairsdialog.open) {
          this.openpairs(this.pairsdialog.matchday)
        }
      }
    }
  },

  apollo: {
    Event: {
      query: gql`
        query($id: UUID!) { Event(id: $id) { ${evquery} }}
      `,
      variables () {
        return {
          id: this.id
        }
      }
    },
    EventFind: {
      query: gql`
          query($parent: UUID!) { EventFind(parent: $parent) { ${query} }}
        `,
      variables () {
        return {
          parent: this.id
        }
      },
      subscribeToMore: {
        document: gql`
          subscription($parent: UUID!) { EventCreate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        updateQuery: updateQuery('EventFind', 'EventCreate')
      }
    },
    $subscribe: {
      SeasonUpdate: {
        query: gql`
          subscription($id: UUID!) { EventUpdate(id: $id) { ${evquery} }}
        `,
        variables () {
          return {
            id: this.id
          }
        }
      },
      EventUpdate: {
        query: gql`
          subscription($parent: UUID!) { EventUpdate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        }
      },
      EventDelete: {
        query: gql`
          subscription($parent: UUID!) { EventDelete(parent: $parent) }
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
